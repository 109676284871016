import dompurify, { type Config, type HookEvent } from 'isomorphic-dompurify';

export type TransformCallback = (currentNode: Element, data: HookEvent, config: Config) => void;

const sanitizeAndTransformHTML = (source: string | Node, config: Config & { transformCallback: TransformCallback }) => {
  dompurify.addHook('afterSanitizeAttributes', config.transformCallback);

  const result = dompurify.sanitize(source, config);

  dompurify.removeHook('afterSanitizeAttributes');

  return result;
};

export default sanitizeAndTransformHTML;
