<script setup lang="ts">
import sanitizeAndTransformHTML, { type TransformCallback } from '~/services/wbckEditor/utils/sanitizeAndTransformHTML';

defineProps<{ markupString?: string }>();

const addTargetBlankToAnchorTag: TransformCallback = (node) => {
  if (node.tagName === 'A' && 'target' in node) {
    node.setAttribute('target', '_blank');

    /**
     * 보안상의 이유로, target=_blank를 넣는다면 rel=noopener/noreferrer를 넣어주는 것이 좋음
     * https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener
     */
    node.setAttribute('rel', 'noopener noreferrer');
  }
};
</script>

<template>
  <!-- Sanitize editor contents except iframe with youtube link -->
  <section
    v-if="markupString"
    class="ck-content select-none"
    v-html="
      sanitizeAndTransformHTML(markupString, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
        transformCallback: addTargetBlankToAnchorTag,
      })
    " />

  <section v-else class="ck-content select-none">
    <slot />
  </section>
</template>

<!-- 여기서 작성하는 스타일은 에디터와 뷰어 둘 다 적용됩니다. -->
<style scoped lang="scss">
.ck-content {
  color: var(--neutral-1000);
  :deep(p) {
    margin: 0;
  }
  :deep(a) {
    text-decoration: underline;
  }
  word-break: break-all;

  :deep(h1) {
    font-size: var(--text-4xl);
    line-height: var(--text-4xl-leading);
    margin: 0;
  }
  :deep(h2) {
    font-size: var(--text-3xl);
    line-height: var(--text-3xl-leading);
    margin: 0;
  }
  :deep(h3) {
    font-size: var(--text-2xl);
    line-height: var(--text-2xl-leading);
    margin: 0;
  }
  :deep(h4) {
    font-size: var(--text-xl);
    line-height: var(--text-xl-leading);
    margin: 0;
  }
  :deep(h5) {
    font-size: var(--text-lg);
    line-height: var(--text-lg-leading);
    margin: 0;
  }
  :deep(hr) {
    height: 1px;
  }
  :deep(ul),
  :deep(ol) {
    padding-left: 24px;
    margin: 0;
  }
  :deep(ul) {
    list-style-type: disc;
  }
  :deep(ul ul),
  :deep(ol ul),
  :deep(ul ol),
  :deep(ol ol) {
    margin: 0;
  }
  :deep(li) {
    margin: 2px 0;
  }
  :deep(iframe) {
    width: 100%;
  }

  :deep(.image) {
    margin: auto;
  }
  /*
   * 아래 table 관련 스타일은 ckeditor5에서 똑같이 만들어줍니다.
   * 그럼에도 여기에 다시 정의한 이유는, ckeditor5가 스타일을 생성하기 전에는 아래 스타일이 먹지 않기 때문입니다.
   */
  :deep(.table table) {
    border: 1px double #b3b3b3;
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  :deep(.table) {
    display: table;
    margin: 0.9em auto;
  }
  :deep(.table table th) {
    background: rgba(0, 0, 0, 0.05);
    font-weight: 700;
  }
  :deep(.table table th),
  :deep(.table table td) {
    border: 1px solid #bfbfbf;
    min-width: 2em;
    padding: 0.4em;
    overflow-wrap: break-word;
    position: relative;
  }
}

/** Quill editor 스타일 대응 */
:deep(.ql-align-center) {
  text-align: center;
}
</style>
